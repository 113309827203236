var component = require('../../../lib/js/component.js');
var requestInfo = require('../../../lib/js/request-info.js');
var utils = require('../../../lib/js/utils.js');

var ResponsiveMedia = require('../responsive-media/responsive-media.js');

function CarouselV2() {
    var component;

    this.initialize = function (element, options) {
        _this = this;
        component = this;
        this.slidesAreLoaded = false;

        this.carouselGuid = utils.generateGuid();
        this.$element.attr("id", "Carousel-V2-" + this.carouselGuid);
        this.$element.attr("data-guid", this.carouselGuid);

        var navIconLeft = "icon-arrow-left",
            navIconRight = "icon-arrow-right";

        var navText = [
            '<span class="sr-only">Previous</span><i class="' + navIconLeft + '"></i>',
            '<span class="sr-only">Next</span><i class="' + navIconRight + '"></i>'
        ];

        this.options = {
            dots: false,
            items: 1,
            loop: true,
            nav: true,
            navElement: 'button',
            navSpeed: 300,
            navText: navText,
            onInitialized: this.onCarouselInitialized,
            onTranslated: this.onCarouselTranslated,
            onRefreshed: this.onCarouselRefresh
        };

        // Load all images in carousel and related slide show on any interaction
        this.$element.on('changed.owl.carousel', function (event) {
            component.handleChangedOwlCarousel(_this.$element, _this.slidesAreLoaded, event);
        });

        // Initialize carousel
        this.$element.find(".Carousel").owlCarousel(this.options);

        // Initialize nav buttons
        var prev = this.$element.find(".owl-prev");
        $(prev).on("click", function (event) {
            event.stopPropagation(); // Prevents modal from appearing
        });

        var next = this.$element.find(".owl-next");
        $(next).on("click", function (event) {
            event.stopPropagation(); // Prevents modal from appearing
        });

        // Initialize lead form
        this.initializeLeadForm(this.$element);

        // Add event listener when Share icon is clicked
        $(document).on('click', '.Carousel-v2 .Share-icon', function (event) {
            event.stopPropagation();
        });

        // Add event listener when Pinterest icon is clicked
        $(document).on('click', '.Carousel-v2 .PinterestLink', function (event) {
            event.stopPropagation();
        });

        this.on( 'keyup', function( e ) {
            if( e.which == 9 ) {
                var cloneItems = $(document).find('.owl-item.cloned:not(.active) a.Social-btn');
                $.each(cloneItems, function(index, item) {
                    $(item).attr('tabindex', -1);
                });
            }
        });


        // Add event listener when CTA is clicked
        var id = "#Carousel-V2-" + this.carouselGuid;
        $(document).on('click', id + ' .Carousel-cta', this.onCarouselCtaClicked);

    }

    this.onCarouselRefresh = function (event) {
        component.setAriaLabels();
        component.setTabIndex();
    }

    this.onCarouselInitialized = function (event) {
        window.dispatchEvent(new Event('resize')); // This is called to force the Carousel to recalculate its visible dimensions.

        component.setAriaLabels();
        component.setTabIndex();

        component.disableSingleImageCarouselSwipe();
    }

    this.onCarouselTranslated = function (event) {
        event.preventDefault();

        component.setAriaLabels();
        component.setTabIndex();

        // Find items inside the carousel that are not active and update their tabindex - handles nested carousels 
        var $inactiveItems = $(event.currentTarget).find(' > .owl-stage-outer > .owl-stage > .owl-item:not(.active)'); 
        $inactiveItems.find('*[tabindex]').attr('tabindex', '-1'); 
    }

    this.onCarouselCtaClicked = function (event) {
        var deeplinkId = $(this).data("deeplinkid");
        if (deeplinkId) {
            deeplinkId = deeplinkId.toString().trim('#');

            var element = document.getElementById(deeplinkId);
            if (element) {
                var boundingClientRect = element.getBoundingClientRect();
                var offset = 100;
                var calculatedTop = boundingClientRect.top + window.scrollY - offset;
                var currentTop = calculatedTop;
                var left = boundingClientRect.left

                // Page will scroll to initial calculated position of the target element
                window.scroll({
                    top: calculatedTop,
                    left: left,
                    behavior: "smooth"
                });

                // While the page scrolls, images will load in which will push the target element further down the page
                // This setInterval will recalculate for the target element's position until the page finally scrolls to the correct position
                var intervalId = setInterval(function () {
                    boundingClientRect = element.getBoundingClientRect();
                    calculatedTop = boundingClientRect.top + window.scrollY - offset;

                    if (currentTop !== calculatedTop) {
                        window.scroll({
                            top: calculatedTop,
                            left: left,
                            behavior: "smooth"
                        });

                        currentTop = calculatedTop;
                    }
                    else if (currentTop === calculatedTop) { // When the correct position for the target element is finally reached, this interval will be cleared
                        clearInterval(intervalId);
                    }
                }, 500);
            }
        }

        event.stopPropagation();
    }

    this.handleChangedOwlCarousel = function (carousel, slidesAreLoaded, event) {
        if (event.property && event.property.name == 'position') { // Only listen to the position changed event

            // make screen reader re-read nav button
            var $focusedElement = $(':focus');

            if ($focusedElement.length > 0) {
                var $parent = $focusedElement.parent();
                $focusedElement.detach();
                setTimeout(function() {
                    if ($focusedElement.hasClass('owl-next'))
                        $parent.append($focusedElement);
                    else
                        $parent.prepend($focusedElement);
    
                    $focusedElement.trigger('focus');
                }, 0);
            }

            if (!slidesAreLoaded) {

                var items = $(carousel).find('.owl-item');             
                items.each(function () {
                    $responsiveImg = $(this).find('img.u-responsiveMedia');

                    // Cloned items are not in DOM at page load and not processed by responsive media
                    if ($(this).hasClass('cloned')) {
                        $responsiveImg.attach(ResponsiveMedia);
                    }

                    $responsiveImg.trigger("RESPONSIVE_LAZYLOAD");

                   // Avoid applying inline display: block to images within .no-display-block containers
                    if ($responsiveImg.closest('.no-display-block').length === 0) {
                        $responsiveImg.on('load', function () {
                            $(this).css('display', 'block');
                        });
                    }                 
                });

                if (items.length > 0) {
                    $(carousel).cloudinary_update();
                    $.cloudinary.responsive();
                    this.setTabIndex();
                    slidesAreLoaded = true;
                }
            }
        }
    }

    this.initializeLeadForm = function () {
        $(document).on('click', '.Carousel-v2 .lead-form-submit', function () {
            $("#communitySpecificForm input[name=CommunityId]").val(0);
            requestInfo.initRequestInfoModals();
        });

        this.$phoneInputs = this.$element.find('input[name="Phone"]');
        if (this.$phoneInputs && this.$phoneInputs.length) {
            for (var index = 0; index < this.$phoneInputs.length; index++) {
                var phoneInput = this.$phoneInputs[index];

                $(phoneInput).change(this.formatPhoneNumber.bind(this));
            }
        }
    }

// ADA
    this.setAriaLabels = function () {
        var owlItems = $('.Carousel-v2 .owl-item:not(.active)');
        var carouselSlides = $(owlItems).find('.Carousel-slide');
        $(carouselSlides).attr({ 'aria-hidden': 'true' });
        var $activeSlide = $('.Carousel-v2 .owl-item.active');
        $activeSlide.find('.Carousel-slide').removeAttr('aria-hidden');
    }

    this.setTabIndex = function () {
        component.find('.owl-prev, .owl-item a, .owl-next, .owl-item .Image-caption, .owl-item img').attr('tabindex', -1);

        component.selectable = [];

        component.selectable.push(component.find('.carousel-modal-close')[0]);

        var leadForm = component.find('.owl-item.active .Carousel-slide__lead-form');
        if (leadForm && leadForm.length) {
            var inputs = leadForm.find('input, .submit-button');
            $.each(inputs, function (index, input) {
                component.selectable.push(input);
            });

            component.selectable.push(component.find('.owl-prev')[0]);
        }
        else {
            component.selectable.push(component.find('.owl-prev')[0]);
        }

        $(".Carousel-v2 .owl-item:not(.active)").find('a,button,img,.Image-caption').attr('tabindex',-1).attr('aria-hidden', true);

        //remove focus from lead form input
        $(".Carousel-v2 .owl-item:not(.active)").find("input,.icon-close").attr('tabindex', -1);

        var carouselSlideItems =
        $('.Carousel-v2 .owl-item.active').find('a:not(.submit-button),button:not(.submit-button),img,.Image-caption ');

        $.each(carouselSlideItems, function (index, item) {
            component.selectable.push(item);
        });


        component.selectable.push(component.find('.owl-next')[0]);

        $.each(component.selectable, function (index, item) {
            $(item).attr('tabindex', 0);
            $(item).attr('aria-hidden',false);
        });
    }

    // Helpers
    this.formatPhoneNumber = function (event) {
        var phoneInput = event.target;
        if (phoneInput) {
            $(phoneInput).val(utils.formatDirtyPhoneNumber($(phoneInput).val()));
        }
    }

    this.disableSingleImageCarouselSwipe = function () {
        // Single Image Carousels have 5 cloned items
        var isSingleImageCarousel = component.find('.owl-item').length === 5;
        if (isSingleImageCarousel) {
            component.find('.owl-stage').css({'pointer-events': 'none', 'cursor': 'pointer'});
            component.find('.Social-btn, .Share-icon').css('pointer-events', 'all');
        }
    }
}

module.exports = component(CarouselV2);
